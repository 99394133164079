.hero-light {
    --name-clr: #f1f1f1;
    --hdr-clr: black;
    --sub-hdr-clr: #353535
}

.hero-dark {
    --name-clr: #101010;
    --hdr-clr: white;
    --sub-hdr-clr: #D9D9D9
}

.name-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    top: 5%;
    left: 0;
}

.floating-name {
    font-size: 14rem;
    color: var(--name-clr);
    white-space: nowrap;
    position: absolute;
    animation: moveText 10s linear infinite;
}

.floating-name span {
    display: inline-block;
}

@keyframes moveText {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

#hero {
    padding: 1rem;
    overflow: hidden;
}

.hero-top {
    display: flex;
    justify-content: space-around;
}

.hero-left,
.hero-right {
    z-index: 2;
    padding: 4rem;
}

.header {
    font-weight: 300;
    font-size: 4rem;
    margin-bottom: 1rem;
    color: var(--hdr-clr);
}

.sub-header {
    color: var(--sub-hdr-clr);
    font-weight: 400;
    font-size: 1.75rem;
    margin-top: 0;
}


@media screen and (max-width: 920px) {
    .floating-name {
        font-size: 7rem;
        top: 2%;
    }

    .hero-left,
    .hero-right {
        padding: 1rem;
    }

    .header {
        font-size: 2.5rem;
    }

    .hero-top {
        align-items: center;
        flex-direction: column;
    }

}