.footer-light {
    --f-bg: #F3F3F3;
    --txt: #262626;
}

.footer-dark {
    --f-bg: #202020;
    --txt: white;
}

#footer {
    position: relative;
    z-index: 2;
    background-color: var(--f-bg);
    color: var(--txt);
    padding: 4rem;
}

.contact-subheader {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 300;
}

.contact-header,
.social-header,
.profiles-header {
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--txt);
}

.msg-box {
    background-color: white;
    width: 50%;
    border-radius: 3rem;
    overflow: hidden;
    display: flex;
}




.msg-box input {
    height: 3rem;
    width: 80%;
    border-radius: 3rem;
    border: none;
    padding: 0 1rem;
    font-size: 1rem;
    color: black;
    font-family: Poppins;
}

.msg-box input::placeholder {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 300;
    color: #999999;
}

.msg-box input:focus {
    outline: none;
}

.msg-box button {
    background-color: #A9FB50;
    border: none;
    height: 3rem;
    width: 8rem;
    border-radius: 3rem;
    cursor: pointer;
}

.send-btn-text {
    color: #262626;
    font-size: 1rem;
    margin: 0;
    font-weight: 300;
}

.social-links,
.profile-links,
.quick-links {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.social-link,
.profile-link,
.quick-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-size: 1.2rem;
    color: var(--txt);
    text-decoration: none;
    font-weight: 300;
    width: 100%;
}

.social-link span,
.profile-link span {
    font-size: 1.1rem;
    font-weight: 300;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
}

.copyright {
    background-color: #c0ff7c;
    padding: 0.75rem;
}

.copyright-text {
    font-size: 1rem;
    color: #363636;
    text-align: center;
}

@media screen and (max-width: 920px) {

    #footer {
        padding: 2rem;
    }

    .contact-subheader {
        font-size: 1.1rem;
    }

    .social-header,
    .contact-header,
    .profiles-header {
        font-size: 1.5rem;
    }

    .msg-box {
        width: 100%;
    }

    .footer-bottom {
        flex-wrap: wrap;
    }

    .blogs {
        display: none;
    }

    .footer-bottom a {
        font-size: 1rem;
    }

    .copyright-text {
        font-size: 0.8rem;
    }
}