.timeline-light {
    --hdr-clr: #262626
}

.timeline-dark {
    --hdr-clr: white;
}

.timeline-header {
    color: var(--hdr-clr);
    font-size: 2.5rem;
    text-align: center;
}

.timeline-header span {
    color: #A9FB50;
}

.events {
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.timeline-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.line {
    border-radius: 0.5rem;
    width: 0.5rem;
    height: 100%;
    background-color: #E5E5E5;
}

@media screen and (max-width: 920px) {
    .events {
        padding: 2rem;
    }

}