.tab-light {
    --txt: #363636;
}

.tab-dark {
    --txt: white;
}


.tab-btn {
    background: none;
    padding: 0.5rem 4rem;
    cursor: pointer;
    z-index: 2;
}

.tab-btn p {
    font-size: 1.3rem;
    color: var(--txt);
    letter-spacing: 12%;
    margin: 0;
}

.active-tab {
    background: linear-gradient(90deg, #a9fb5294, #8afba88e);
}

@media screen and (max-width: 920px) {

    .tab-btn {
        width: 80%;
    }
}