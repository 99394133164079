.swiper {
    width: 80%;
    height: 80%;
    border-radius: 1rem;
    overflow: hidden;
}

.swiper-slide {
    text-align: center;
    font-size: 2rem;
    background: #fff;
    padding: 0;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    width: 100%;
    object-fit: cover;
}

.autoplay-progress {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #A9FB50;
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #A9FB50;
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}

.mob-slide {
    display: none;
}

@media screen and (max-width: 920px) {
    .swiper {
        width: 90%;
        height: 50vh;
    }

    .swiper-slide {
        height: 50vh;
    }

    .swiper-slide img {
        height: 100%;
        object-fit: cover;
        object-position: left;
    }

    .mob-slide {
        display: block;
    }

    .desk-slide {
        display: none;
    }

}