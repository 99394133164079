.fortefolio-light {
    --bg: #F3F3F3;
    --hdr: #0D0D0D;
    --txt: #545454;
}

.fortefolio-dark {
    --bg: rgba(24, 24, 24, 0.5);
    --hdr: white;
    --txt: #EAEAEA;
}

#fortefolio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg);
    padding: 4rem;
    margin: 4rem 0;
    margin-bottom: 0;
    backdrop-filter: blur(10px);
}

.fortefolio-header {
    color: var(--hdr);
    font-size: 2.5rem;
    margin: 0;
}

.fortefolio-right {
    display: flex;
    justify-content: center;
}

.fortefolio-text {
    font-size: 1.35rem;
    color: var(--txt);
}

.fortefolio-img {
    width: 80%;
}

@media screen and (max-width: 920px) {
    #fortefolio {
        padding: 2rem;
        flex-direction: column;
    }

    .fortefolio-header {
        font-size: 1.5rem;
    }

    .fortefolio-text {
        font-size: 1.1rem;
    }

}